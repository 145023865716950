<template>
  <v-app :class="getKeyboardOpen ? 'keyboard-open' : ''">
    <!-- drawer sinistro di navigazione categorie -->
    <Navbar
      v-if="showApp"
      :key="key"
      :links="navBarLinks"
      :user="user"
      class="noPrint"
    />
    <v-navigation-drawer
      class="drawer-left secondary darken-1"
      v-if="showApp"
      v-model="drawerLeft"
      :temporary="true"
      touchless
      floating
      app
      top
      :width="$vuetify.breakpoint.xs ? '90%' : '50%'"
      min-width="50%"
    >
      <MenuLinksList :links="navBarLinks" @close="drawerLeft = false" />
    </v-navigation-drawer>

    <!-- drawer destro di carrello -->
    <v-navigation-drawer
      v-if="showApp && isAuthenticated"
      v-model="drawerRight"
      touchless
      fixed
      temporary
      clipped
      right
      app
      width="600"
    >
      <div>
        <CartInfoList :isCart="true" />
      </div>

      <CartSummary class="position-bottom w-100 pb-4" />
    </v-navigation-drawer>

    <v-main>
      <div>
        <v-skeleton-loader
          type="image,actions,article,image,article"
          v-if="loading"
          :loading="loading"
          class="container"
        ></v-skeleton-loader>
        <router-view v-else></router-view>
      </div>
      <CookieConsent :isApp="$platform_is_cordova" />
    </v-main>

    <v-fab-transition v-if="showApp">
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
        class="noPrint"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-footer v-if="showApp" class="footer py-3 mb-8 mt-9 white">
      <Footer />
    </v-footer>
  </v-app>
</template>
<script>
import CartSummary from "@/components/cart/CartSummary";
import CartInfoList from "@/components/cart/CartInfoList";
// import CategoryTree from "@/components/navigation/CategoryTree";
// import MasonryCategoryMenu from "@/components/navigation/MasonryCategoryMenu";
import MenuLinksList from "@/components/navigation/MenuLinksList";
import Footer from "@/components/navigation/Footer";
import Navbar from "@/components/navigation/Navbar";
// import SearchPanel from "@/components/navigation/SearchPanel";
//import BottomNavBar from "@/components/navigation/BottomNavBar";
//import InviteButtons from "@/components/navigation/InviteButtons";
import CookieConsent from "@/components/CookieConsent";
import CmService from "~/service/cmService";
import login from "~/mixins/login";
import version from "~/mixins/version";
import { mapActions, mapGetters } from "vuex";
import pushNotification from "~/mixins/pushNotification";
// import SecurityService from "~/service/securityService";

export default {
  name: "App",
  components: {
    //CategoryTree,
    CartInfoList,
    CartSummary,
    //MasonryCategoryMenu,
    MenuLinksList,
    Footer,
    Navbar,
    // SearchPanel,
    //InviteButtons,
    //BottomNavBar,
    CookieConsent
  },
  mixins: [login, version, pushNotification],
  data() {
    return {
      fab: false,
      load404: false,
      initialHref: window.location.href,
      loading: true,
      key: 1,
      navBarLinks: [
        { title: "Home", url: "/" },
        { title: "Ricerca avanzata", url: "/test1" },
        { title: "Sfoglia", url: "/category" },
        { title: "Profilo", url: "/profile/profile-update" }
      ],
      keyboardOpen: false
    };
  },
  metaInfo: {
    title: "GS1",
    titleTemplate: "%s | GS1",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content: "GS1"
      },
      {
        name: "keywords",
        content: "GS1"
      },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "author", content: "Digitelematica s.r.l." }
    ]
  },
  computed: {
    ...mapGetters({
      needRestart: "needRestart",
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isHome() {
      return this.$route.name === "Home";
    },
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    drawerRight: {
      get() {
        return this.$store.state.app.drawerRight;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerRight", value);
      }
    },
    drawerLinks: {
      get() {
        return this.$store.state.custom.drawerLinks;
      },
      set(value) {
        this.$store.commit("custom/updatedDrawerLinks", value);
      }
    },
    drawerSearch: {
      get() {
        return this.$store.state.custom.drawerSearch;
      },
      set(value) {
        this.$store.commit("custom/updatedDrawerSearch", value);
      }
    },
    showApp: function() {
      return !this.load404;
    },
    getKeyboardOpen: function() {
      return this.keyboardOpen;
    }
  },
  methods: {
    ...mapActions({
      updateAuth: "cart/updateAuth",
      resetFilters: "category/resetFilters",
      updateLastStartTime: "app/updateLastStartTime"
    }),
    fetchNavBarLinksMenu() {
      CmService.getMenuByLocation("primary").then(
        data => (this.navBarLinks = data)
      );
    },
    fetchFooterLinksMenu() {
      CmService.getMenuByLocation("footer").then(
        data => (this.footerLinks = data)
      );
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    async onResume() {
      var _this = this;
      var hash;
      setTimeout(async function() {
        hash = global.vm.lodash.findIndex(global.config.hashNotReload, function(
          o
        ) {
          return window.location.hash.indexOf(o) !== -1;
        });
        var needRestart =
          new Date().getTime() - _this.$store.state.app.lastStartTime >
          global.config.reloadAfterMillis;
        if (hash === -1 && needRestart) {
          global.EventBus.$emit("restart");
        } else {
          if (
            hash === -1 &&
            window.sessionStorage.getItem("is-authenticated")
          ) {
            await _this.loadCart();
          }
          global.EventBus.$emit("reload");
        }
      }, 100);
    },
    async handleLogin() {
      try {
        let PageUrl = window.location.href;
        let pageName = PageUrl.substring(PageUrl.lastIndexOf("/") + 1);
        if (pageName == "login-tech") {
          console.info("login tech page dont show the ext login popup");
        } else {
          this.doLogin("response419");
        }
      } catch (ex) {
        console.warn("error in getting the pagename");
        console.warn(ex);
        this.doLogin("response419");
      }
    },
    async onDeepLinks(path, queryString) {
      var _this = this;
      var currentQuery = {};
      console.log("onDeepLinks :::: ", path);
      console.log("onDeepLinks queryString :::: ", queryString);
      console.log("_this.$router :::: ", _this.$router);
      setTimeout(async function() {
        if (queryString) {
          currentQuery.q = queryString.replace("q=", "").replace(/%20/g, " ");
          console.log("onDeepLinks currentQuery :::: ", currentQuery);
          _this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        } else {
          _this.$router.push(path);
        }
      }, 100);
    },
    async refreshLogin() {
      if (global.config.pushNotificationEnabled) {
        // console.log("REFRESH LOGIN - REGISTER FIREBASE DEVICE");
        // Al Deviceready facciamo un refresh login per controllare se l'utente è ancora collegato
        // await SecurityService.refreshLogin();
        // await this.registerFirebaseDevice();
      }
    },
    ...mapActions({
      loadCart: "cart/loadCart",
      reload: "category/loadCategoryTree"
    })
  },
  async created() {
    //this.fetchNavBarLinksMenu();
    //this.fetchFooterLinksMenu();
    this.reload();
    var _this = this;
    _this.load404 = false;
    try {
      if (
        window.sessionStorage.getItem("is-authenticated") ||
        window.sessionStorage.getItem("X-Ebsn-Session") ||
        window.localStorage.getItem("X-Ebsn-Account")
      ) {
        await _this.loadCart();
      }
    } finally {
      _this.loading = false;
    }

    global.EventBus.$on("isNotLoggedIn", () => {
      _this.updateAuth();
      _this.handleLogin();
    });
    global.EventBus.$on("restart", () => {
      _this.updateLastStartTime();
      window.location.reload();
    });
    global.EventBus.$on("resetFilters", () => {
      _this.resetFilters(Math.random());
    });
    setTimeout(function() {
      _this.key = _this.key + 1;
    }, 3000);
    //eventi legati a cordova, partono solo al deviceready che viene scatenato da cordova quanto la app è pronta
    document.addEventListener(
      "deviceready",
      () => {
        console.log("deviceready ::::: ", window.innerWidth);
        setTimeout(function() {
          if (window.innerWidth < 600) {
            screen.orientation.lock("portrait");
          }
        }, 1500);

        //save last start time
        _this.updateLastStartTime();

        document.addEventListener("resume", _this.onResume, false);

        // TODO: Gestire la lista dei Routes da controllare su config.
        window.IonicDeeplink.route(
          global.config.deeplinks,
          function(match) {
            console.log("MATCH :::: ", match);
            _this.onDeepLinks(match.$link.path, match.$link.queryString);
          },
          function(nomatch) {
            console.log("NOMATCH :::: ", nomatch);
          }
        );

        //con x secondi controllo se c'è una versione più recente
        _this.checkVersion();

        // Plugin Keyboard
        window.addEventListener("keyboardDidHide", () => {
          // Describe your logic which will be run each time keyboard is closed.
          console.log("keyboardDidHide");
          _this.keyboardOpen = false;
        });
        window.addEventListener("keyboardDidShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardDidShow");
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardWillShow");
        });
        window.addEventListener("keyboardWillHide", () => {
          // Describe your logic which will be run each time when keyboard is about to be closed.
          console.log("keyboardWillHide");
        });
        _this.refreshLogin();
      },
      false
    );
  },
  watch: {
    $route(to) {
      this.load404 = false;
      if (to.meta.load404) {
        this.load404 = to.meta.load404;
      }
    }
  }
};
</script>
